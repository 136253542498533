window.addEventListener('DOMContentLoaded', event => {
    // Simple-DataTables
    // https://github.com/fiduswriter/Simple-DataTables/wiki

    const vegetablesIndex = document.getElementById('vegetablesIndex');
    if (vegetablesIndex) {
        new simpleDatatables.DataTable(vegetablesIndex, {
            perPageSelect: [30, 50, 100, 200],
            perPage: 100
        });
    }

    const vegGradesIndex = document.getElementById('vegGradesIndex');
    if (vegGradesIndex) {
        new simpleDatatables.DataTable(vegGradesIndex);
    }

    const factoryProductsIndex = document.getElementById('factoryProductsIndex');
    if (factoryProductsIndex) {
        new simpleDatatables.DataTable(factoryProductsIndex);
    }

    const vegetableUnitsIndex = document.getElementById('vegetableUnitsIndex');
    if (vegetableUnitsIndex) {
        new simpleDatatables.DataTable(vegetableUnitsIndex);
    }

    const packagingsIndex = document.getElementById('packagingsIndex');
    if (packagingsIndex) {
        new simpleDatatables.DataTable(packagingsIndex);
    }

    const varietiesIndex = document.getElementById('varietiesIndex');
    if (varietiesIndex) {
        new simpleDatatables.DataTable(varietiesIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 50
        });
    }

    const mainDestinationsIndex = document.getElementById('mainDestinationsIndex');
    if (mainDestinationsIndex) {
        new simpleDatatables.DataTable(mainDestinationsIndex);
    }

    const farmsIndex = document.getElementById('farmsIndex');
    if (farmsIndex) {
        new simpleDatatables.DataTable(farmsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 30,
            labels: {
                placeholder: "白菜, パートナー, 団体 etc.",
            }
        });
    }

    const discardedFarmsIndex = document.getElementById('discardedFarmsIndex');
    if (discardedFarmsIndex) {
        new simpleDatatables.DataTable(discardedFarmsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 10
        });
    }

    const farmVegsIndex = document.getElementById('farmVegsIndex');
    if (farmVegsIndex) {
        new simpleDatatables.DataTable(farmVegsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 30
        });
    }

    const factoriesIndex = document.getElementById('factoriesIndex');
    if (factoriesIndex) {
        new simpleDatatables.DataTable(factoriesIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 30
        });
    }

    const discardedFactoriesIndex = document.getElementById('discardedFactoriesIndex');
    if (discardedFactoriesIndex) {
        new simpleDatatables.DataTable(discardedFactoriesIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 10
        });
    }

    const contractsIndex = document.getElementById('contractsIndex');
    if (contractsIndex) {
        new simpleDatatables.DataTable(contractsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 30
        });
    }

    const discardedContractsIndex = document.getElementById('discardedContractsIndex');
    if (discardedContractsIndex) {
        new simpleDatatables.DataTable(discardedContractsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 10
        });
    }

    const transactionsIndex = document.getElementById('transactionsIndex');
    if (transactionsIndex) {
        new simpleDatatables.DataTable(transactionsIndex);
    }

    const allTransactionsIndex = document.getElementById('allTransactionsIndex');
    if (allTransactionsIndex) {
        new simpleDatatables.DataTable(allTransactionsIndex, {
            perPageSelect: [10, 20, 30, 50, 100, 200],
            perPage: 50
        });
    }

    const nonApVegTransactionsIndex = document.getElementById('nonApVegTransactionsIndex');
    if (nonApVegTransactionsIndex) {
        new simpleDatatables.DataTable(nonApVegTransactionsIndex, {
            perPageSelect: [50, 100, 200, 500, 1000, 2500],
            perPage: 500
        }); 
    }

    const contractSectionIndex = document.getElementsByClassName('contractSectionIndex');
    if (contractSectionIndex) {
        for(var i = 0; i < contractSectionIndex.length; i++) {
            new simpleDatatables.DataTable(contractSectionIndex[i]);
        }
    }

    const logiCompaniesIndex = document.getElementById('logiCompaniesIndex');
    if (logiCompaniesIndex) {
        new simpleDatatables.DataTable(logiCompaniesIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 30
        });
    }

    const discardedLogiCompaniesIndex = document.getElementById('discardedLogiCompaniesIndex');
    if (discardedLogiCompaniesIndex) {
        new simpleDatatables.DataTable(discardedLogiCompaniesIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 10
        });
    }

    const farmFieldsIndex = document.getElementById('farmFieldsIndex');
    if (farmFieldsIndex) {
        new simpleDatatables.DataTable(farmFieldsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 20
        });
    }

    const farmReviewsIndex = document.getElementById('farmReviewsIndex');
    if (farmReviewsIndex) {
        new simpleDatatables.DataTable(farmReviewsIndex, {
            paging: false,
            searchable: false,
            sortable: false
        });
    }

    const supplyCalendarTable = document.getElementById('supplyCalendarTable');
    if (supplyCalendarTable) {
        new simpleDatatables.DataTable(supplyCalendarTable, {
            paging: false,
            sortable: false,
            searchable: false,
            footer: true
        });
    }

    const quoteCandidatesTable = document.getElementById('quoteCandidatesTable');
    if (quoteCandidatesTable) {
        new simpleDatatables.DataTable(quoteCandidatesTable, {
            paging: false,
            searchable: false
        });
    }

    const salesIndex = document.getElementsByClassName('salesIndex');
    if (salesIndex) {
        for(var i = 0; i < salesIndex.length; i++) {
            new simpleDatatables.DataTable(salesIndex[i], {
                paging: false,
                layout: {
                    top: "",
                    bottom: "{search}"
                }
            });
        }
    }

    const ratingIndex = document.getElementsByClassName('ratingIndex');
    if (ratingIndex) {
        for(var i = 0; i < ratingIndex.length; i++) {
            new simpleDatatables.DataTable(ratingIndex[i], {
                perPageSelect: [10, 20, 30, 50, 100],
                perPage: 50
            });
        }
    }

    const farmSurveyIndex = document.getElementById('farmSurveyIndex');
    if (farmSurveyIndex) {
        new simpleDatatables.DataTable(farmSurveyIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 50
        });
    }

    const salesActivitiesIndex = document.getElementById('salesActivitiesIndex');
    if (salesActivitiesIndex) {
        new simpleDatatables.DataTable(salesActivitiesIndex, {
            paging: false,
            searchable: false,
            sortable: false
        });
    }

    const salesActivitiesTab = document.getElementById('salesActivitiesTab');
    if (salesActivitiesTab) {
        new simpleDatatables.DataTable(salesActivitiesTab, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 20
        });
    }

    const farmReviewsTab = document.getElementById('farmReviewsTab');
    if (farmReviewsTab) {
        new simpleDatatables.DataTable(farmReviewsTab, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 20
        });
    }

    const chemicalsIndex = document.getElementById('chemicalsIndex');
    if (chemicalsIndex) {
        new simpleDatatables.DataTable(chemicalsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 50
        });
    }

    const discardedChemicalsIndex = document.getElementById('discardedChemicalsIndex');
    if (discardedChemicalsIndex) {
        new simpleDatatables.DataTable(discardedChemicalsIndex, {
            perPageSelect: [10, 20, 30, 50, 100],
            perPage: 10
        });
    }
});
